import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Form, Label, Input } from "reactstrap";
import classes from "./Familymember.module.css";
import { DatePicker } from "antd";
import dayjs from "dayjs";
// import { DatePicker } from "reactstrap-date-picker";
function FamilyMember(props) {
  const {
    count,
    name,
    souscripteur,
    setSouscripteur,
    item,
    dn,
    nom,
    prenom,
    civility,
  } = props;

  const ageHandler = (e) => {
    
    let tempCi = [...souscripteur];
    let temp = tempCi.find(
      (i) => i.relationship_rank === item?.relationship_rank
    );
    temp.dn = e;

    setSouscripteur(tempCi);
  };
  const nomHandler = (e) => {
    let tempCi = [...souscripteur];
    let temp = tempCi.find(
      (i) => i.relationship_rank === item?.relationship_rank
    );
    temp.nom = e.target.value;
    setSouscripteur(tempCi);
  };
  const prenomHandler = (e) => {
    let tempCi = [...souscripteur];
    let temp = tempCi.find(
      (i) => i.relationship_rank === item?.relationship_rank
    );
    temp.prenom = e.target.value;
    setSouscripteur(tempCi);
  };

  const civiliteHandler = (e) => {
    let tempCi = [...souscripteur];
    let temp = tempCi.find(
      (i) => i.relationship_rank === item?.relationship_rank
    );
    temp.civility = e.target.value;
    setSouscripteur(tempCi);
  };
  const icon = () => {
    switch (count) {
      case 1:
        return (
          <svg
            style={{ fill: "#254484", marginRight: "1rem", height: "1.5rem" }}
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M23,11H21V9a1,1,0,0,0-2,0v2H17a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V13h2a1,1,0,0,0,0-2Z" />
            <path d="M9,12A6,6,0,1,0,3,6,6.006,6.006,0,0,0,9,12ZM9,2A4,4,0,1,1,5,6,4,4,0,0,1,9,2Z" />
            <path d="M9,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,9,14Z" />
          </svg>
        );

      default:
        return (
          <svg
            style={{ fill: "#254484", marginRight: "1rem", height: "1.5rem" }}
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M7.5,13A4.5,4.5,0,1,1,12,8.5,4.505,4.505,0,0,1,7.5,13Zm0-7A2.5,2.5,0,1,0,10,8.5,2.5,2.5,0,0,0,7.5,6ZM15,23v-.5a7.5,7.5,0,0,0-15,0V23a1,1,0,0,0,2,0v-.5a5.5,5.5,0,0,1,11,0V23a1,1,0,0,0,2,0Zm9-5a7,7,0,0,0-11.667-5.217,1,1,0,1,0,1.334,1.49A5,5,0,0,1,22,18a1,1,0,0,0,2,0ZM17.5,9A4.5,4.5,0,1,1,22,4.5,4.505,4.505,0,0,1,17.5,9Zm0-7A2.5,2.5,0,1,0,20,4.5,2.5,2.5,0,0,0,17.5,2Z" />
          </svg>
        );
    }
  };
  const deleteHandler = () => {
    let temp = [...souscripteur];
    let temp2 = temp.find(
      (i) => i.relationship_rank === item?.relationship_rank
    );
    temp.forEach((a, index) => {
      if (
        a.relationship_rank > item?.relationship_rank &&
        item?.relationship_rank !== 2
      ) {
        a.relationship_rank = a.relationship_rank - 1;
      }
    });
    temp.splice(temp.indexOf(temp2), 1);
    setSouscripteur(temp);
    // setCostumerInformation((prevState) =>
    //   prevState.filter((i) => i.relationship_rank !== item?.relationship_rank)
    // );
  };
  return (
    <Accordion defaultActiveKey="1" style={{ marginBottom: 15 }}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <div className={classes.accordionHeader}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {icon()}
              <h5 className={classes.titre}> {name} </h5>
            </div>
            {dn && (
              <h6 className={classes.age}>
                {" "}
                {dn &&
                  dayjs(Date.now()).format("YYYY") -
                    dayjs(dn).format("YYYY")}
                ans{" "}
              </h6>
            )}
            <div className={classes.deleteButton} onClick={deleteHandler} id={`BTN Supprimer ${name}`}> 
              <svg
                style={{ height: "1.5rem" }}
                id="Outline"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <title>50 bin</title>
                <path d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z" />
                <path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z" />
                <path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" />
              </svg>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <Form className={classes.family}>
            <Label for="civilté" className={classes.inputTitle}>
              Civilité <span>*</span>
            </Label>
            <Input
              type="select"
              value={civility}
              id={`Civilte ${name}`}
              onChange={civiliteHandler}
              // onChange={(e) => {
              //   let news = [...souscripteur];
              //   news[0].civility = e.target.value;
              //   setSouscripteur(news);
              // }}
            >
              <option value=""></option>
              <option value="Mme">Mme</option>
              <option value="Mr">M</option>
            </Input>
            <Label for="nom" className={classes.inputTitle}>
              Nom <span>*</span>
            </Label>
            <Input
              id={`Nom ${name}`}
              required
              value={nom}
              onChange={nomHandler}
            />

            <Label for="Prenom" className={classes.inputTitle}>
              Prénom <span>*</span>
            </Label>
            <Input
              id={`Prénom ${name}`}
              required
              value={prenom}
              onChange={prenomHandler}
            />

            <Label for="Anneé" className={classes.inputTitle}>
              Date de naissance{" "}
              <p style={{ color: "red", paddingInline: "0.2rem" }}> * </p>
            </Label>
            {/* <Input
              type="date"
              value={dn ? dn : ""}
              onChange={ageHandler}
              name="select"
              id="Anneé"
            > */}
            <DatePicker
              style={{ width: "100%", padding: ".45rem .75rem" }}
              onChange={ageHandler}
              format="DD/MM/YYYY"
              placeholder="jj/mm/aaaa"
              id={`Date naissance ${name}`}
              defaultValue={souscripteur.find((e)=>e.relationship_rank===item.relationship_rank)?.dn? dayjs(souscripteur.find((e)=>e.relationship_rank===item.relationship_rank)?.dn) : ""}
            />
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FamilyMember;
