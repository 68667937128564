/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import classes from "./SendEmail.module.css";
import { Row, Col, Container } from "reactstrap";

import { Button } from "react-bootstrap";
import axios from "../../../utils/axios";

import Swal from "sweetalert2";
import EditorText from "./EditorText/EditorText";
import PreviewEmail from "./PreviewEmail/PreviewEmail";
import ParcoursContext from "../../../Context/ParcoursContext";
// import moment from "moment";
import { Alert, Snackbar } from "@mui/material";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function SendEmail(props) {
  const {
    modal,
    toggle,
    setModal,
    devis,
    saveToSession,
    createHistory,
    setOpenbackdrop,
  } = props;
  //eslint-disable-next-line
  const {
    souscripteur,
    backgroundColor,
    logo,
    proposition,
    destination,
    objetEmail,
    templateDevis,
    setEditorMessage,
    editorMessage,
  } = React.useContext(ParcoursContext);
  React.useEffect(() => {
    if (saveToSession) {
      saveToSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [openf, setOpenf] = useState("");
  // const [message, setMessage] = useState(
  //   `<p style='text-align:center; color:black'><strong>Bonjour  ${souscripteur[0]?.prenom} ${souscripteur[0]?.nom},</strong></p>   <p>Suite à notre entretien, je vous transmets notre solution en matière de protection juridique avec notre produit Sérénité.   Pour visualiser notre proposition, veuillez cliquer sur le lien suivant :   </p>`
  // );
  //   const sendEmail = () => {
  //     let emailHeader = `
  //    <div
  //    style="
  //     background-color: ${backgroundColor};
  //     color: white;
  //     font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  //     position:relative;
  //     padding:1rem
  //   "
  //  >

  //  <table width="100%" border="0"  cellspacing="0" cellpadding="0">
  //     <tr>
  //     <td width="45%">
  //     <img alt="logo"  src=
  //      "https://sdn.geoprod.com/static/uploads/organismes/logo/1662364820.224509logo-Asolution-geoprod.png"/>
  //     </td>
  //         <td>

  //         <h2 style="display:inline;left:auto;right:auto"> Devis </h2>
  //          </td>
  //     </tr>
  // </table>

  //   </div>
  //     `;
  //     let emailFooter = `<div
  //     style="
  //     margin-left: auto;
  //     margin-right: auto;
  //       align-items: center;
  //     "
  //   >
  //     <div
  //       id="link_det"
  //       align="center"
  //       style="
  //         padding-right: 40px;
  //         padding-left: 40px;
  //         padding: 15px;
  //         color: #ffffff;
  //         text-decoration: none;
  //         text-transform: uppercase;
  //         background-color: #e8291b;
  //         border-radius: 6px;
  //         font-family:Arial, Helvetica, sans-serif;
  //       "
  //     >
  //     <img src="https://sdn.as-solution.cloud.geoprod.com/static/uploads/cmp/908499056/1664187072.75361__arrow.png" style="height:0.80rem; width:1.10rem; padding-right:0.5rem ">
  //       <a
  //         href="${props.devis}"
  //         style="
  //           color: #ffffff;
  //           text-decoration: none;
  //           text-transform: uppercase;
  //           letter-spacing: 4px;
  //           display: inline-block;
  //         "
  //         >DEVIS</a
  //       >
  //     </div>
  //     <div
  //       style="
  //         padding: 35px;
  //         padding-top: 0px;
  //         color: #515151;
  //         font-family: 'Chivo', sans-serif;
  //       "
  //     >
  //       <p
  //         style="text-align: center; line-height: 35px"
  //         id="conforme_sante"
  //       ></p>
  //       <p style="text-align: center; line-height: 35px">
  //         Je reste à votre entière disposition pour tout complément
  //         d'informations.<br />
  //         Je vous remercie de la confiance que vous nous témoignez, en espérant
  //         avoir répondu à vos attentes.<br />Cordialement,
  //       </p>
  //     </div>
  //   </div>`;
  //     let element = `<div  >
  //          ${emailHeader}
  //          ${message}
  //          ${emailFooter}
  //     </div>`;
  //     let lienRepriseAdhesion = sessionStorage.getItem("repriseAdhesion");
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_GEOPROD_APIURL}/send_mail_prospect_new`,
  //         {
  //           adresse_mail: destination,
  //           subject: objetEmail,
  //           message: element,
  //           id_prospect: "",
  //           id_opp: sessionStorage.getItem("SessionURLID"),
  //           active: 0,
  //           lien: `${process.env.REACT_APP_FRONTOFFICE_PREPROD}/Proposition/InformationBancaires?IdAdhesion=${lienRepriseAdhesion}&partnership_id=${localStorage.getItem("api_key")}`,
  //           // files: [props.devis],
  //         },
  //         {
  //           headers: {
  //             idSession: `${localStorage.getItem("token")}`,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         if (res.data.error === false) {
  //           Swal.fire({
  //             position: "center",
  //             icon: "success",
  //             title: "E-mail envoyé avec succès !",
  //             showConfirmButton: false,
  //             timer: 1500,
  //           });
  //           setModal(!modal);
  //         } else {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Erreur...",
  //             text: res.data?.messsage,
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         // Swal.fire({
  //         //   icon: 'error',
  //         //   title: 'Oops...',
  //         //   text: "Quelque chose s'est mal passé !",
  //         // });
  //         console.log(err);
  //       });
  //   };

  const sendDevisEmail = async () => {
    var regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[a-zA-Z]{2,4}$/)
    let validEmail = regex.test(destination.replace(/ /g, ""));
    if (destination && validEmail) {
      setOpenbackdrop(true);

      createHistory();
      // let emailHeader = `
      //       <div
      //       style="
      //        background-color: ${backgroundColor};
      //        color: white;
      //        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      //        position:relative;
      //        padding:1rem
      //      "
      //     >

      //     <table width="100%" border="0"  cellspacing="0" cellpadding="0">
      //        <tr>
      //        <td width="45%">
      //        <img alt="logo"  src=
      //         "https://sdn.geoprod.com/static/uploads/organismes/logo/1662364820.224509logo-Asolution-geoprod.png"/>
      //        </td>
      //            <td>

      //            <h2 style="display:inline;left:auto;right:auto"> Devis </h2>
      //             </td>
      //        </tr>
      //    </table>

      //      </div>
      //        `;
      // let emailFooter = `<div
      //        style="
      //        margin-left: auto;
      //        margin-right: auto;
      //          align-items: center;
      //        "
      //      >
      //        <div
      //          id="link_det"
      //          align="center"
      //          style="
      //            padding-right: 40px;
      //            padding-left: 40px;
      //            padding: 15px;
      //            color: #ffffff;
      //            text-decoration: none;
      //            text-transform: uppercase;
      //            background-color: #e8291b;
      //            border-radius: 6px;
      //            font-family:Arial, Helvetica, sans-serif;
      //          "
      //        >
      //        <img src="https://sdn.as-solution.cloud.geoprod.com/static/uploads/cmp/908499056/1664187072.75361__arrow.png" style="height:0.80rem; width:1.10rem; padding-right:0.5rem ">
      //          <a
      //            href="${props.pdf}"
      //            style="
      //              color: #ffffff;
      //              text-decoration: none;
      //              text-transform: uppercase;
      //              letter-spacing: 4px;
      //              display: inline-block;
      //            "
      //            >DEVIS</a
      //          >
      //        </div>
      //        <div
      //          style="
      //            padding: 35px;
      //            padding-top: 0px;
      //            color: #515151;
      //            font-family: 'Chivo', sans-serif;
      //          "
      //        >
      //          <p
      //            style="text-align: center; line-height: 35px"
      //            id="conforme_sante"
      //          ></p>
      //          <p style="text-align: center; line-height: 35px">
      //            Je reste à votre entière disposition pour tout complément
      //            d'informations.<br />
      //            Je vous remercie de la confiance que vous nous témoignez, en espérant
      //            avoir répondu à vos attentes.<br />Cordialement,
      //          </p>
      //        </div>
      //      </div>`;
      // let element = `<div  >
      //             ${emailHeader}
      //             ${message}
      //             ${emailFooter}
      //        </div>`;
      let lienRepriseAdhesion = sessionStorage.getItem("repriseAdhesion");
      fetch(devis)
        .then((response) => response.blob())
        .then((blob) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });
        })
        .then((result) => {
          let prospectInfo = JSON.parse(
            sessionStorage.getItem("souscripteur")
          ).find((e) => e.relationship_rank === 1);
          const base64Data = result.split(",")[1];
          let template_to_send = templateDevis.replace(
            "*|FNAME|*",
            prospectInfo?.nom ? (prospectInfo?.nom).trim() : ""
          );
          template_to_send = template_to_send.replace(
            "*|LNAME|*",
            prospectInfo?.prenom ? (prospectInfo?.prenom).trim() : ""
          );

          template_to_send = template_to_send.replace(
            "[NOM GARANTIE]",
            proposition?.product_range === "PJ VIE PRIVE + OPTION"
              ? "Pj vie privée premium"
              : "Pj vie privée"
          );
          // template_to_send = template_to_send.replace(
          //   "[NOM PRODUIT]", "fff"
          // );
          template_to_send = template_to_send.replace(
            "[--urlDevis--]",
            "https://as-solutions.fr/"
          );

          var doc_to_send = {
            "CG PJ VIE PRIVEE - AS SOLUTIONS V09-2022 .pdf":
              "https://sdn.as-solution.cloud.geoprod.com/static/uploads/CFDP docs/CG PJ VIE PRIVEE - AS SOLUTIONS V09-2022.pdf",
            "IPID VIE PRIVEE - AS SOLUTIONS V09-2022.pdf":
              "https://sdn.as-solution.cloud.geoprod.com/static/uploads/CFDP docs/IPID VIE PRIVEE - AS SOLUTIONS V09-2022.pdf",
            "Statuts mis à jour 16112022.pdf":
              "https://sdn.as-solution.cloud.geoprod.com/static/uploads/CFDP docs/Statuts mis à jour 16112022.pdf",
          };
          let docs_b64 = [];

          function fetchAndConvertToBase64(name, url) {
            return fetch(url)
              .then((response) => response.blob())
              .then((blob) => {
                return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onloadend = () => resolve(reader.result);
                  reader.onerror = reject;
                  reader.readAsDataURL(blob);
                });
              })
              .then((result) => {
                let b64 = result.split(",")[1];
                return {
                  type: "b64",
                  name: name,
                  content: b64,
                };
              });
          }

          Promise.all(
            Object.entries(doc_to_send).map(([name, url]) =>
              fetchAndConvertToBase64(name, url)
            )
          ).then((results) => {
            docs_b64 = results;
            axios
              .post(
                `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/mailchimp/send_mail`,
                {
                  recipient: destination,
                  sender: "devis@as-solutions.fr",
                  subject: "Devis",
                  body: "",
                  html: template_to_send,
                  id_opp: sessionStorage.getItem("SessionURLID"),
                  url: `${
                    window.location.origin
                  }/Proposition/InformationBancaires?IdAdhesion=${lienRepriseAdhesion}&partnership_id=${localStorage.getItem(
                    "api_key"
                  )}`,
                  attachments: [
                    {
                      type: "b64",
                      name: "Devis.pdf",
                      content: base64Data,
                    },
                    ...docs_b64
                  ],
                }
              )
              .then((res) => {
                setOpenbackdrop(false);
                if (res.data.error === false) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "E-mail envoyé avec succès !",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              })
              .catch((err) => {
                setOpenbackdrop(false);
              });
          });
        });
    } else if (destination && !validEmail) {
      setOpenf("Veuillez vérifier votre adresse E-mail.");
    } else {
      setOpenf("Veuillez remplir votre adresse E-mail");
    }
  };
  return (
    <>
      <Modal show={modal} fullscreen onHide={toggle}>
        <Modal.Header style={{display:"flex", justifyContent:"end"}} >
        <FontAwesomeIcon
            icon={faXmark}
            id="BTN_close_modal"
            style={{ height: "22px", cursor: "pointer" }}
            onClick={() => {
              setModal(false);
            }}/>
        </Modal.Header>
        <Modal.Body>
          <Container fluid className={classes.contain}>
            <Row>
              <Col xs="12" lg="6">
                <EditorText
                  message={editorMessage}
                  onChange={setEditorMessage}
                />
              </Col>
              <Col xs="12" lg="6">
                <PreviewEmail message={editorMessage} />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={sendDevisEmail} id="BTN_envoyer_devis">
            Envoyer
          </Button>
        </Modal.Footer>
        <Row>
          <Col>
            <Snackbar
              open={openf !== ""}
              autoHideDuration={3000}
              onClose={() => setOpenf("")}
            >
              <Alert
                onClose={() => setOpenf("")}
                severity="error"
                sx={{ width: "100%" }}
              >
                {openf}
              </Alert>
            </Snackbar>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default SendEmail;
