import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import { useNavigate } from "react-router-dom";
import ParcoursContext from "../../Context/ParcoursContext";
import moment from "moment/moment";
import axios from "../../utils/axios";
import classes from "./Proposition.module.css";
import SendEmail from "./SendEmail/SendEmail";

import { Col, Row } from "reactstrap";
import {  Backdrop } from "@mui/material";
import pdfIcon from "../../assets/images/pdf.png";
import mailIcon from "../../assets/images/mail.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import Card from "../../components/Card/Card";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import { ConfigProvider, FloatButton, Spin, notification } from "antd";
import { alertFunction } from "../../utils/alertFunction";
import { ClearOutlined } from "@ant-design/icons";
import dayjs from "dayjs";


export default function Proposition(props) {
  const [checked, setChecked] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  // const [openf, setOpenf] = React.useState(false);
  const [openbackdrop, setOpenbackdrop] = React.useState(false);

  // const [idUrl, setIdUrl] = React.useState(sessionStorage.getItem("idUrl"));
  const navigate = useNavigate();
  const [propositions, setPropositions] = React.useState();

  const {
    souscripteur,
    setCustomerInformations,
    customerInformations,
    proposition,
    productColor,
    confirmButtonColor,
    villeOptions,
    retourButtonColor,
  } = React.useContext(ParcoursContext);

  const [pdf, setPdf] = React.useState();
  React.useEffect(() => {

    let customerInformations = [];
    souscripteur.forEach((val) => {
      customerInformations.push({
        number: 476819 + val?.relationship_rank,
        primary_subscriber: val?.primary_subscriber,
        insured: true,
        beneficiary: false,
        birth_info: {
          date: dayjs(val.dn).format("DD/MM/YYYY"),
        },
        address: {
          zip_code: souscripteur[0]?.cp,
          code_insee: souscripteur[0]?.code_insee,
          

        },
        job: {
          csp_insee: "Toutes",
          regime: "Régime général",
          number_yes: 0,
          force_yes: false,
        },
        contract_information: {
          number: 476820,
         product: proposition?.product_range === "PJ VIE PRIVE"
          ? [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT_SIMPLE]
          : [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT],
        },
        relationship: val?.relationship,
        relationship_rank: val?.relationship_rank,
      });
    });
    let productsIds = {};

    for (let i = 0; i < customerInformations.length; i++) {
      productsIds[0] = Array(customerInformations.length).fill([
        parseInt(process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT),
      ]);
      productsIds[1] = Array(customerInformations.length).fill([
        process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT_SIMPLE,
      ]);
    }
    sessionStorage.setItem("globalData",JSON.stringify(customerInformations))
  
  
  
    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/pricing/get_mass_pricing`,
        {
          business_introducer: {
            login: "2556",
          },
          third_app: {
            name: "ASolutions",
            id_external: 476820,
          },
          customer_information: customerInformations,
          contract_information: {
            effective_date: moment()
              .add(1, "month")
              .startOf("month")
              .format("DD/MM/yyyy"),
            mode_splitting: "Mensuel",
          },
          products: productsIds,
        },
        {
          headers: {
            idSession: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data?.data[0]?.success === false) {
        } else {
          setPropositions(res.data);
          setCustomerInformations(customerInformations);
        }
      })
      .catch((err) => console.log(err));
    var listProspects = [];

    souscripteur
      .filter((element) => element?.relationship_rank >= 2)
      .forEach((element) => {
        listProspects = [
          ...listProspects,
          {
            name: element?.nom,
            surname: element?.prenom,
            DN: dayjs(element?.dn).format("YYYY-MM-DD"),
            id: "",
            id_opp: "",
            campagne_id: "",
            post: "",
            interlocuteur: "",
            ville: souscripteur[0]?.ville,
            situation: element?.relationship === "married" ? "married" : "",
            subs_tag:
              element?.relationship_rank === 2
                ? "Conjoint"
                : "Enfant" + (element?.relationship_rank - 10),
            users_nom: "",
            users_prenom: "",
            adresse_mail: "",
            civilite: element.civility,
            prospects_civ: "",

            prospects_situation: "",
            nb_enfants: "",
            adresses_adresse: "",
            streetNumber: "",
            CP: souscripteur[0]?.cp,
            streetName: souscripteur[0]?.adresse,
            adresses_ville: "",
            complement_adr: "",
            tel: "",
            tel2: "",
            mobile: "",
            first_campagne: "",
            description: "",
            etat: "",
            lat: "",
            latitude: null,
            longitude: null,
            commercieux_id: "",
            commentaire: "",
            geom: "",
            tags: [],
            lng: "",
            date_naiss: "",
            collab: "",
            id_pros: "",
            id_camp: "",
            coment_plus: "",
            code: "",
            rue: "",
            numero: "",
            complement: "",
            mail: "",
            nb_enfant: "",
            infcomp: "",
            relation:
              element?.relationship === "married" ? "Conjoint" : "Descendant",
            pays: "",
            invalidForms: [],
            deleted: false,
            information_bancaire: {
              id: "",
              titulaire_compte: "",
              iban: "",
              bic_swift: "",
              type_iban: "",
              nom_banque: "",
            },
            prospects_id: "",
          },
        ];
      });
    let offre = {
      info_specifiques_campagnes: {},
      info_specifiques_ligne_produit: {},
      prospects: {
        listProspects: listProspects,
        mainProspect: {
          interlocuteur: null,
          tags: [],
          invalidForms: [],
          id: null,
          prospects_id: null,
          post: null,
          ville: souscripteur[0]?.ville,
          situation: null,
          adresse_mail: souscripteur[0]?.mail,
          civilite: souscripteur[0]?.civility,
          name: souscripteur[0]?.prenom,
          surname: souscripteur[0]?.nom,
          DN: dayjs(souscripteur[0]?.dn).format("YYYY-MM-DD"),
          nb_enfants:
            souscripteur[1]?.relationship === "married"
              ? souscripteur.length - 2
              : souscripteur.length - 1,
          streetNumber: null,
          longitude: null,
          latitude: null,
          CP: souscripteur[0]?.cp,
          streetName: souscripteur[0]?.adresse,
          complement_adr: null,
          tel: souscripteur[0]?.phone,
          tel2: null,
          mobile: null,
          description: null,
          relation: "principal",
          subs_tag: "Prospect",
        },
      },
    };
    axios
      .post(`${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/offre/add`, offre, {
        headers: {
          idSession: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        sessionStorage.setItem("SessionURLID", res.data?.id_opportunite_md5);
        sessionStorage.setItem("id_prospect", res.data?.id_prospect_md5);
        sessionStorage.setItem(
          "_subs_tags",
          JSON.stringify(res.data?._subs_tags)
        );
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const createHistory = () => {
    let id_pros = JSON.parse(
      sessionStorage.getItem("_subs_tags")
    )?.id_prospect_md5_with_givenTgas;
    let newCustomerInformations = [];
    customerInformations.forEach((element, index) => {
      newCustomerInformations.push({
        number: element?.relationship_rank,
        primary_subscriber: element?.primary_subscriber,
        insured: true,
        beneficiary: false,
        birth_info: {
          date: element.birth_info.date,
        },
        address: {
          street_name:
            souscripteur[0]?.adresse +
            ", " +
            souscripteur[0].cp +
            ", " +
            souscripteur[0].ville,
          zip_code: souscripteur[0]?.cp,
          city: souscripteur[0]?.ville,
          code_insee: souscripteur[0]?.code_insee,
        },
        id_pros:
          element.relationship_rank === 1
            ? Object.keys(id_pros).find((key) => id_pros[key] === "Prospect")
            : element.relationship_rank === 2
            ? Object.keys(id_pros).find((key) => id_pros[key] === "Conjoint")
            : Object.keys(id_pros).find(
                (key) =>
                  id_pros[key] === "Enfant" + (element?.relationship_rank - 10)
              ),
        job: {
          csp_insee: "Toutes",
          regime: "Régime général",
          number_yes: 0,
          force_yes: false,
        },
        contract_information: {
          number: 476820,
          product:
            proposition?.product_range === "PJ VIE PRIVE" ? ["1149"] : ["1148"],
        },
        relationship: element?.relationship,
        relationship_rank: element?.relationship_rank,
        mail: souscripteur[0]?.mail,
        identity: {
          civility: souscripteur[index].civility,
          use_name: souscripteur[index].nom,
          first_name: souscripteur[index].prenom,
        },
        phone: [
          {
            type: "Mobile",
            country_prefix: souscripteur[0].country_prefix,
            number: souscripteur[0]?.phone,
          },
        ],
      });
    });
    /* customerInformations.forEach((element, index) => {
      newCustomerInformations.push({
        number: element?.relationship_rank,
        primary_subscriber: element?.primary_subscriber,
        insured: true,
        beneficiary: false,
        birth_info: {
          date: element?.birth_info.date,
        },
        address: {
          street_name: souscripteur[0]?.adresse,
          zip_code: souscripteur[0]?.cp,
          city: souscripteur[0]?.ville,
        },
        id_pros:
          element.relationship_rank === 1
            ? Object.keys(id_pros).find((key) => id_pros[key] === "Prospect")
            : element.relationship_rank === 2
              ? Object.keys(id_pros).find((key) => id_pros[key] === "Conjoint")
              : Object.keys(id_pros).find(
                (key) =>
                  id_pros[key] ===
                  "Enfant" + (element?.relationship_rank - 10)
              ),
        job: {
          csp_insee: "Toutes",
          regime: "Régime général",
          number_yes: 0,
          force_yes: false,
        },
        contract_information: {
          number: 476820,
          product:
            proposition?.product_range === "PJ VIE PRIVE" ? [1140] : [1141],
        },
        relationship: element?.relationship,
        relationship_rank: element?.relationship_rank,
        mail: souscripteur[0]?.mail,
        identity: {
          civility: souscripteur[index].civility,
          // civility: souscripteur[0]?.civility === "mme" ? "Madame" : "Monsieur",
          // element?.relationship_rank === 1
          //   ? "Monsieur"
          //   : element?.relationship_rank === 2
          //   ? "Madame"
          //   : "Monsieur",
          use_name: souscripteur[index]?.nom,
          first_name: souscripteur[index]?.prenom,
        },
        phone: [
          {
            type: "Mobile",
            country_prefix: souscripteur[0]?.country_prefix,
            number: souscripteur[0]?.phone,
          },
        ],
      });
    }); */

    let repriseAdhesion = sessionStorage.getItem("id_prospect") + Date.now();
    sessionStorage.setItem("repriseAdhesion", repriseAdhesion);
    axios.post(
      `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/session_url/set_object_from_session_url`,
      {
        id: repriseAdhesion,
        objet: {
          proposition: proposition,
          customerInfo: newCustomerInformations,
          souscripteur: souscripteur,
          id_opp: sessionStorage.getItem("SessionURLID"),
          _subs_tags: JSON.parse(sessionStorage.getItem("_subs_tags")),
          villeOptions: villeOptions,
        },
      }
    );
  };

  // const createHistory = () => {
  //   let id_pros = JSON.parse(
  //     sessionStorage.getItem("_subs_tags")
  //   )?.id_prospect_md5_with_givenTgas;
  //   let newCustomerInformations = [];
  //   customerInformations.forEach((element, index) => {
  //     newCustomerInformations.push({
  //       number: element?.relationship_rank,
  //       primary_subscriber: element?.primary_subscriber,
  //       insured: true,
  //       beneficiary: false,
  //       birth_info: {
  //         date: element.birth_info.date,
  //       },
  //       address: {
  //         street_name:
  //           souscripteur[0]?.adresse +
  //           ", " +
  //           souscripteur[0].cp +
  //           ", " +
  //           souscripteur[0].ville,
  //         zip_code: souscripteur[0]?.cp,
  //         city: souscripteur[0]?.ville,
  //         code_insee: souscripteur[0]?.code_insee,
  //       },
  //       id_pros:
  //         element.relationship_rank === 1
  //           ? Object.keys(id_pros).find((key) => id_pros[key] === "Prospect")
  //           : element.relationship_rank === 2
  //           ? Object.keys(id_pros).find((key) => id_pros[key] === "Conjoint")
  //           : Object.keys(id_pros).find(
  //               (key) =>
  //                 id_pros[key] === "Enfant" + (element?.relationship_rank - 10)
  //             ),
  //       job: {
  //         csp_insee: "Toutes",
  //         regime: "Régime général",
  //         number_yes: 0,
  //         force_yes: false,
  //       },
  //       contract_information: {
  //         number: 476820,
  //         product:
  //           proposition?.product_range === "PJ VIE PRIVE" ? ["1149"] : ["1148"],
  //       },
  //       relationship: element?.relationship,
  //       relationship_rank: element?.relationship_rank,
  //       mail: souscripteur[0]?.mail,
  //       identity: {
  //         civility: souscripteur[index].civility,
  //         use_name: souscripteur[index].nom,
  //         first_name: souscripteur[index].prenom,
  //       },
  //       phone: [
  //         {
  //           type: "Mobile",
  //           country_prefix: souscripteur[0].country_prefix,
  //           number: souscripteur[0]?.phone,
  //         },
  //       ],
  //     });
  //   });

  //   const repriseAdhesion = uuidv4();
  //   sessionStorage.setItem("repriseAdhesion", repriseAdhesion);
  //   const plainText = JSON.stringify({
  //     proposition: { proposition: proposition },
  //     customerInfo: newCustomerInformations,
  //     souscripteur: souscripteur,
  //     _subs_tags: JSON.parse(sessionStorage.getItem("_subs_tags")),
  //   });
  //   const simpleCrypto = new SimpleCrypto(
  //     process.env.REACT_APP_ENCRYPTION_SECRET_KEY
  //   );
  //   const cipherText = simpleCrypto.encrypt(plainText);
  //   axios.post(
  //     `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/session_url/set_object_from_session_url`,
  //     {
  //       id: repriseAdhesion,
  //       objet: {
  //         data: cipherText,
  //         id_opp: sessionStorage.getItem("SessionURLID"),
  //       },
  //     }
  //   );
  // };

  const toggle = () => {
    if (proposition.product_range) {
      notification.destroy();

      setModal(!modal);
    } else {
      alertFunction("Vous devez choisir une proposition.", "error");
    }
  };
  // const uploadDevisOpportunite = async (file_url) => {
  //   const response = await fetch(file_url);
  //   const blob = await response.blob();

  //   let f = new FormData();
  //   f.append("file[]", blob, "devis.pdf");
  //   f.append("id_pros", sessionStorage.getItem("id_prospect"));
  //   f.append("id_document", 117);
  //   f.append("id_opp", sessionStorage.getItem("SessionURLID"));
  //   // f.append("name_doc", "devis_");

  //   axios
  //     .post(
  //       `${process.env.REACT_APP_GEOPROD_APIURL}upload_document_opportunite`,
  //       f,
  //       {
  //         headers: {
  //           idSession: localStorage.getItem("token"),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setOpenbackdrop(false);
  //     })
  //     .catch((err) => {
  //       setOpenbackdrop(false);
  //       console.log(err);
  //     });
  // };

  // const sendEmail = async () => {
  //   if (proposition.product_range) {
  //     setOpenbackdrop(true);
  //     axios
  //       .post(
  //         process.env.REACT_APP_EDITIQUE + "/editique_pdf/",
  //         {
  //           idModel: process.env.REACT_APP_DEVIS_MODEL,
  //           data: {
  //             nom_adherent: souscripteur[0]?.nom,
  //             prenom_adherent: souscripteur[0]?.prenom,
  //             conseil: entreprise?.nom,
  //             date_naissance: moment(souscripteur[0]?.dn).format("DD-MM-YYYY"),
  //             lieu_naissance: souscripteur[0]?.adresse,
  //             code_postal: souscripteur[0]?.cp,
  //             ville: souscripteur[0]?.ville,
  //             adresse:
  //               souscripteur[0]?.adresse +
  //               ", " +
  //               souscripteur[0].cp +
  //               ", " +
  //               souscripteur[0].ville,
  //             civilite: souscripteur[0]?.civility === "Madame" ? "Mme" : "Mr",
  //             date_effet_originale: moment()
  //               .add(1, "month")
  //               .startOf("month")
  //               .format("DD/MM/yyyy"),

  //             cot_an: proposition.total_yearly.toString(),
  //             cot_men: proposition.total_monthly.toString(),

  //             email: souscripteur[0]?.mail,
  //             // mail: souscripteur[0]?.mail,
  //             num_devis: Date.now().toString(),
  //             num_telephone:
  //               souscripteur[0]?.country_prefix + souscripteur[0]?.phone,
  //             opt_premium: proposition.number === "1" ? "non" : "oui",
  //           },
  //         },
  //         {
  //           headers: {
  //             apiKey: process.env.REACT_APP_EDITIQUE_APIKEY,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         setPdf(res.data?.file_url);
  //         uploadDevisOpportunite(res.data?.file_url);
  //         setOpenbackdrop(false);
  //         const idSessionURL = Date.now() + SessionStorageService().getId();
  //         sessionStorage.setItem("idUrl", idSessionURL);
  //         setIdUrl(idSessionURL);
  //         if (modal === false) {
  //           createHistory();
  //           setModal(!modal);
  //         }
  //         setModal(!modal);
  //       })
  //       .catch((err) => {
  //         setOpenbackdrop(false);
  //         console.log(err);
  //       });
  //   } else {
  //     setOpenf(true);
  //   }
  // };

  // const devisDownload = async () => {
  //   if (proposition.product_range) {
  //     setOpenbackdrop(true);
  //     axios
  //       .post(
  //         process.env.REACT_APP_EDITIQUE + "/editique_pdf/",
  //         {
  //           idModel: process.env.REACT_APP_DEVIS_MODEL,
  //           data: {
  //             nom_adherent: souscripteur[0]?.nom,
  //             conseil: entreprise?.nom,
  //             prenom_adherent: souscripteur[0]?.prenom,
  //             date_naissance: moment(souscripteur[0]?.dn).format("DD-MM-YYYY"),
  //             lieu_naissance: souscripteur[0]?.adresse,
  //             code_postal: souscripteur[0]?.cp,
  //             ville: souscripteur[0]?.ville,
  //             adresse:
  //               souscripteur[0]?.adresse +
  //               ", " +
  //               souscripteur[0].cp +
  //               ", " +
  //               souscripteur[0].ville,
  //             civilite: souscripteur[0]?.civility === "Madame" ? "Mme" : "Mr",
  //             date_effet_originale: moment(Date.now())
  //               .add(1, "days")
  //               .format("DD-MM-YYYY"),
  //             cot_an: proposition?.total_yearly.toString(),
  //             cot_men: proposition?.total_monthly.toString(),

  //             email: souscripteur[0]?.mail,
  //             mail: souscripteur[0]?.mail,
  //             num_devis: Date.now().toString(),
  //             num_telephone:
  //               souscripteur[0]?.country_prefix + souscripteur[0]?.phone,
  //             opt_premium: proposition?.number === "1" ? "non" : "oui",
  //           },
  //         },
  //         {
  //           headers: {
  //             apiKey: process.env.REACT_APP_EDITIQUE_APIKEY,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         setPdf(res.data?.file_url);
  //         window.open(res.data?.file_url, "_blank");

  //         uploadDevisOpportunite(res.data?.file_url);
  //       })
  //       .catch((err) => {
  //         setOpenbackdrop(false);
  //         console.log(err);
  //       });
  //   } else {
  //     setOpenf(true);
  //   }
  // };
  const generateDevis = (type) => {
    let principalSous=souscripteur.find((e)=>e.relationship_rank===1)
    if (proposition.product_range) {
      notification.destroy();

      setOpenbackdrop(true);
      let customerInfo = [
        {
          number: 1,
          primary_subscriber: true,
          insured: true,
          beneficiary: false,
          birth_info: {
            date: dayjs(principalSous?.dn).format("DD/MM/YYYY"),
          },
          address: {
            street_name: principalSous?.adresse,
            zip_code: principalSous?.cp,
            city: principalSous?.ville,
          },
          job: {
            csp_insee: "Toutes",
            regime: "Régime général",
          },
          contract_information: {
            number: 476820,
            product:
            proposition?.product_range === "PJ VIE PRIVE"
                ? [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT_SIMPLE]
                : [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT],
          },
          relationship: "primary subscriber",
          relationship_rank: 1,
          identity: {
            use_name: principalSous?.nom,
            first_name: principalSous?.prenom,
          },
          phone: [
            {
              type: "Mobile",
              country_prefix: principalSous?.country_prefix,
              number: principalSous?.phone,
            },
          ],
        },
      ];
      axios
        .post(
          `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/devis/generate_devis`,
          {
            id_opp: sessionStorage.getItem("SessionURLID"),
            document_produit: "protection_juridique_vie_prive",
            data_pricing: {
              option: [],
              third_app: {
                name: "ASolutions",
                id_external: 476820,
              },
              customer_information: customerInfo,
              contract_information: {
                effective_date: moment()
                .add(1, "month")
                .startOf("month")
                .format("DD/MM/yyyy"),
                mode_splitting: "Mensuel",
              },
            },
            data_fields: {
              email: souscripteur[0]?.mail,
              civilite: souscripteur[0]?.civility,
              opt_premium: proposition?.product_range === "PJ VIE PRIVE" ?"Non":"Oui"
            },
          },
          {
            headers: {
              apiKey: localStorage.getItem("api_key"),
            },
          }
        )
        .then((response) => {
          if (type === "exporter") {
            window.open(response?.data?.devis, "_blank");
          }
          if (type === "mail") {
            toggle();
          }
          setPdf(response?.data?.devis);
          setOpenbackdrop(false);
        })
        .catch((err) => {
          console.log(err);
          setOpenbackdrop(false);
        });
    } else {
      alertFunction("Vous devez choisir une proposition.", "error");
    }
  };
  return (
    <>
      <BackgroundImage color={productColor} />
      <section className={classes.section}>
        <SendEmail
          toggle={toggle}
          modal={modal}
          // idURL={idUrl}
          setModal={setModal}
          createHistory={createHistory}
          url={"/devis/"}
          devis={pdf}
          // setOpenf={setOpenf}
          generateDevis={generateDevis}
          setOpenbackdrop={setOpenbackdrop}
        />

        <Container
          maxWidth="lg"
          style={{ padding: "2rem", alignItems: "center",minHeight:"93vh" }}
        >
          <Row>
            <Col className={classes.propositionCol}>
              <p
                className={classes.propositionText}
                style={{ color: productColor }}
              >
                Proposition d'assurance
              </p>
            </Col>
          </Row>
          {/* <Row>
            <Col className={classes.tarificationCol}>
              <p className={classes.tarificationText}>
                Nous vous avons déjà envoyé un e-mail contenant le devis de
                tarification
              </p>
            </Col>
          </Row> */}

          <div className={classes.box} style={{ backgroundColor: "white" }}>
            {propositions ? (
              <div className={classes.box}>
                <Row>
                  <Col className={classes.switchComponent}>
                    <p
                      className={
                        checked === true
                          ? classes.mensuelle
                          : classes.mensuelleChecked
                      }
                    >
                      Mensuelle
                    </p>
                    <Switch
                     id="switch_cotisation"
                      color="primary"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    <p
                      className={
                        checked === true
                          ? classes.annuelle
                          : classes.annuelleChecked
                      }
                    >
                      Annuelle
                    </p>
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col>
                    <p className={classes.garantieChoix}>
                      choisissez la garantie qui vous convient
                    </p>
                  </Col>
                </Row>
                <Row className={classes.propositionBoxs}>
                  {propositions.data.map((val, index) => {
                    return proposition.product_range === val.product_range ? (
                      <Card
                        key={index}
                        index={index}
                        val={val}
                        selected={true}
                        checked={checked}
                      />
                    ) : (
                      <Card
                        key={index}
                        index={index}
                        val={val}
                        selected={false}
                        checked={checked}
                      />
                    );
                  })}
                </Row>
                <Row>
                  <Col style={{ display: "flex" }}>
                    <Button
                      className={classes.buttons}
                      onClick={() => {
                        generateDevis("mail");
                      }}
                      id="BTN_envoyer_devis_email"
                    >
                      {" "}
                      <img
                        src={mailIcon}
                        alt="mail"
                        style={{ marginRight: "0.5rem" }}
                      />{" "}
                      Envoyer E-mail
                    </Button>

                    <Button
                      className={classes.buttons}
                      onClick={() => generateDevis("exporter")}
                      id="BTN_exporter_devis"
                    >
                      {" "}
                      <img
                        src={pdfIcon}
                        alt="pdf"
                        style={{ marginRight: "0.5rem" }}
                      />{" "}
                      Exporter PDF
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <ConfigProvider
                theme={{
                  components: {
                    Spin: {
                      colorPrimary: "rgb(245, 34, 45)",
                    },
                  },
                }}
              >
                <Spin
                  size="large"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: "1.5rem",
                  }}
                />
              </ConfigProvider>
            )}
          </div>

          <Row >
            <Col className={classes.RetourCol}>
              <Button
              // disabled={!propositions}
                className={classes.retourButton}
                style={{
                  backgroundColor: retourButtonColor 
                }}
                onClick={() => navigate(`/?partnership_id=${localStorage.getItem("api_key")}`)}
                id="BTN_retour_2"
              >
                {" "}
                <FontAwesomeIcon
                  style={{ marginRight: "0.5rem" }}
                  icon={faArrowLeft}
                />
                Retour
              </Button>
            </Col>
            <Col>
              <Button
              // disabled={!propositions}
                className={classes.confirmButton}
                
                style={{
                  backgroundColor:  confirmButtonColor,
                }}
                onClick={() => {
                  if (proposition.product_range) {
                    notification.destroy();

                    navigate(`/Proposition/InformationBancaires/?partnership_id=${localStorage.getItem("api_key")}`);
                  } else {
                    alertFunction(
                      "Vous devez choisir une proposition.",
                      "error"
                    );
                  }
                }}
                id="BTN_confirmer_2"
              >
                Confirmer{" "}
                <FontAwesomeIcon
                  style={{ marginLeft: "0.5rem" }}
                  icon={faArrowRight}
                />
              </Button>
            </Col>
          </Row>
          <FloatButton
            icon={<ClearOutlined />}
            tooltip={<div>Remise à zero</div>}
            onClick={() => {
              sessionStorage.clear();
             window.location.replace(`/?partnership_id=${localStorage.getItem("api_key")}`);
            }}
            id="BTN_clear_2"
          />
        </Container>

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openbackdrop}
          /* onClick={handleCloseBackdrop} */
        >
          <div className={classes.loaderContainer}>
            <Box sx={{ width: "100%" }}>
              <CircularProgress style={{ width: "4rem", height: "4rem" }} />
            </Box>
            <h2>Veuillez patienter un instant.</h2>
          </div>
        </Backdrop>
      </section>
    </>
  );
}
