import React, { useEffect } from "react";
import classes from "./PreviewEmail.module.css";
/* import { useNavigate } from "react-router-dom"; */

import ParcoursContext from "../../../../Context/ParcoursContext";
function PreviewEmail() {
  const { /* setModal, */templateDevis,editorMessage,proposition} =
    React.useContext(ParcoursContext);
  const [previewTemplate, setPreviewTemplate] = React.useState("");

  /*  const navigate = useNavigate(); */
  /*  const RedirectToDevis = () => {
    setModal(false);
    navigate(`${url}${sessionStorage.getItem("idUrl")}`);
  }; */
  useEffect(() => {
    const prospect =JSON.parse(sessionStorage.getItem("souscripteur"))?.find((e)=>e?.relationship_rank===1);
    let temp = templateDevis.replace(
      "*|FNAME|*",
      prospect?.nom ? (prospect?.nom).trim() : ""
    );
    temp = temp.replace("*|LNAME|*", prospect?.prenom ? (prospect?.prenom).trim() : "");
    // temp = temp.replace("[NOM OFFRE]", type!=="comparatif"? (prospect?.formule || 1):"");
    // temp=temp.replace("[NOM PRODUIT]", type!=="comparatif"?(sessionStorage.getItem("nom_cie")+" - FULL SANTE" ):"")
    temp = temp.replace("[NOM GARANTIE]",proposition?.product_range==="PJ VIE PRIVE + OPTION"?"Pj vie privée premium" :"Pj vie privée");
    temp = temp.replace(
      "[--urlDevis--]",
      "https://as-solutions.fr/"
    );
    setPreviewTemplate(temp);
    // eslint-disable-next-line
  }, [editorMessage]);
  return (
    <div style={{ minHeight: "100%" }}>
      <h6> Visuel:</h6>
      <div className={classes.contain}>
        <div dangerouslySetInnerHTML={{ __html: previewTemplate }} />

        {/* <Row
          className={classes.header}
          style={{ backgroundColor: backgroundColor }}
        >
          <Col>
            <h2 className={classes.title}>Devis</h2>
            {image && <img className={classes.head} alt="logo" src={image} />}
          </Col>
        </Row>

        <Row className={classes.rowemail}>
          <Col>
            <p dangerouslySetInnerHTML={{ __html: message }}></p>
          </Col>
        </Row>
        <Row className={classes.rowbtn}>
          <Col className={classes.btn}>
            <Button size="lg" block style={{ backgroundColor: devisBackgroundColor}}>
              <img
                src="https://sdn.as-solution.cloud.geoprod.com/static/uploads/cmp/908499056/1664187072.75361__arrow.png"
                alt="icon"
                style={{
                  height: "1rem",
                  width: "1.5rem",
                  paddingRight: "0.5rem",
                  marginBottom: "0.22rem",
                }}
              />
              DEVIS
            </Button>
          </Col>
        </Row>
        <Row className={classes.rowtext}>
          <Col className={classes.text}>
            <p>
              Je reste à votre entière disposition pour tout complément
              d'informations. Je vous remercie de la confiance que vous nous
              témoignez, en espérant avoir répondu à vos attentes. Cordialement,
            </p>
          </Col>
        </Row> */}
      </div>
    </div>
  );
}

export default PreviewEmail;
