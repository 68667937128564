import React from "react";
import { Row, Col, Form, Button } from "reactstrap";
import { Backdrop } from "@mui/material";
import classes from "./InformationsBancaires.module.css";
import ParcoursContext from "../../Context/ParcoursContext";
import axios from "../../utils/axios";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import InputComponent from "../../components/Input/InputComponent";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import { alertFunction } from "../../utils/alertFunction";
import { FloatButton, notification } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

function InformationsBancaires() {
  const {
    souscripteur,
    setSouscripteur,
    customerInformations,
    setCustomerInformations,
    productColor,
    retourButtonColor,
    confirmButtonColor,
    setProposition,
    proposition,
    setFree,
    setVilleOptions,
  } = React.useContext(ParcoursContext);
  const navigate = useNavigate();
  // const [proposition, setproposition] = React.useState(params?.state);
  const [openbackdrop, setOpenbackdrop] = React.useState(false);
  const rows = [
    [
      {
        label: "Adresse",
        value: souscripteur[0]?.adresse,
        disabled: true,
        name: "adresse",
        type: "text",
      },
    ],
    [
      {
        label: "Ville",
        value: souscripteur[0]?.ville,
        disabled: true,
        name: "ville",
        type: "text",
      },
      {
        label: "Code postal",
        value: souscripteur[0]?.cp,
        disabled: true,
        name: "cp",
        type: "text",
      },
    ],
    [
      {
        label: "BIC prélèvement",
        value: souscripteur[0]?.bic,
        disabled: false,
        name: "bic",
        type: "text",
        required: true,
      },
      {
        label: "IBAN prélèvement",
        value: souscripteur[0]?.iban,
        disabled: false,
        name: "iban",
        type: "text",
        required: true,
      },
    ],
    [
      {
        label: "BIC remboursement",
        value: souscripteur[0]?.bicremboursement,
        disabled: false,
        name: "bicremboursement",
        type: "text",
      },
      {
        label: "IBAN remboursement",
        value: souscripteur[0]?.ibanremboursement,
        disabled: false,
        name: "ibanremboursement",
        type: "text",
      },
    ],
    [
      {
        label: "Jour de prélèvement",
        value: souscripteur[0]?.jourprelevement,
        disabled: false,
        name: "jourprelevement",
        type: "select",
      },
      {
        label: "Nom de la banque prélèvement",
        value: souscripteur[0]?.banquePrelevement,
        disabled: false,
        name: "banquePrelevement",
        type: "text",
      },
    ],
    [
      {
        label: "Nom de la banque remboursement",
        value: souscripteur[0]?.banqueRemboursement,
        disabled: false,
        name: "banqueRemboursement",
        type: "text",
      },
    ],
    [
      {
        label: "Nom et Prénom du débiteur",
        value: souscripteur[0]?.nomDebiteur,
        disabled: false,
        type: "text",
        name: "nomDebiteur",
      },
    ],
  ];

  function isValidIBan(iban) {
    // iban = souscripteur[0].iban;
    if (iban) {
      
      var regex = new RegExp(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,27}/);
      let validIban = regex.test(iban.replace(/ /g, ""));
      return validIban;
    }
  }

  function isValidBic(bic) {
    // bic = souscripteur[0].bic;
    if (bic ) {
      var regex = new RegExp(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?/);
      let validBic = regex.test(bic.replace(/ /g, ""));
      return validBic;
    }
  }

  React.useEffect(() => {
    let id_pros = JSON.parse(
      sessionStorage.getItem("_subs_tags")
    )?.id_prospect_md5_with_givenTgas;

    const id = new URLSearchParams(window.location.search).get("IdAdhesion");
    if (id) {
      axios
        .get(
          `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/session_url/get_object_from_session_url/${id}`
        )
        .then((res) => {
          if (res.data?.error === true) {
            // Swal.fire({
            //   icon: 'error',
            //   title: 'Erreur',
            //   text: res.data?.message.message,
            // });
            alertFunction(res.data?.message.message, "error");
            setOpenbackdrop(false);
          }
          setProposition(res.data?.proposition);
          setCustomerInformations(res.data?.customerInfo);
          setSouscripteur(res.data?.souscripteur);
          sessionStorage.setItem(
            "souscripteur",
            JSON.stringify(res.data?.souscripteur)
          );

          sessionStorage.setItem("SessionURLID", res.data?.id_opp);
          sessionStorage.setItem(
            "_subs_tags",
            JSON.stringify(res.data?._subs_tags)
          );
          setVilleOptions(res.data.villeOptions);
          setFree(false);
          // setNomDebiteur(
          //   res.data?.souscripteur[0]?.nom +
          //     " " +
          //     res.data?.souscripteur[0]?.prenom
          // );
        })
        .catch((err) => {
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Erreur',
          //   text: err,
          // });
          alertFunction(err, "error");
          setOpenbackdrop(false);
        });
    } else  {
      let newCustomerInformations = [];
      customerInformations?.forEach((element, index) => {
        var email = "";
        if (element?.relationship_rank === 1) {
          email = souscripteur[0]?.mail;
        } else if (element?.relationship_rank === 2) {
          email = "conjoint" + souscripteur[0]?.mail;
        } else {
          email =
            "Enfant" +
            (element?.relationship_rank - 10) +
            souscripteur[0]?.mail;
        }
        newCustomerInformations.push({
          number: element?.relationship_rank,
          primary_subscriber: element?.primary_subscriber,
          insured: true,
          beneficiary: false,
          birth_info: {
            date: element.birth_info.date,
          },
          address: {
            street_name:
              souscripteur[0]?.adresse,
              // ", " +
              // souscripteur[0].cp +
              // ", " +
              // souscripteur[0].ville,
            zip_code: souscripteur[0]?.cp,
            city: souscripteur[0]?.ville,
            code_insee: souscripteur[0]?.code_insee,
          },
          id_pros:
            element?.relationship_rank === 1
              ? Object.keys(id_pros).find((key) => id_pros[key] === "Prospect")
              : element.relationship_rank === 2
              ? Object.keys(id_pros).find((key) => id_pros[key] === "Conjoint")
              : Object.keys(id_pros).find(
                  (key) =>
                    id_pros[key] ===
                    "Enfant" + (element?.relationship_rank - 10)
                ),
          job: {
            csp_insee: "Toutes",
            regime: "Régime général",
          },
          contract_information: {
            // number: 476820,
            product:
              proposition?.product_range === "PJ VIE PRIVE"
                ? [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT_SIMPLE]
                : [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT],
          },
          relationship: element?.relationship,
          relationship_rank: element?.relationship_rank,
          mail: email,
          identity: {
            // civility: souscripteur[0]?.civility,
            civility: souscripteur[index]?.civility==="Mr"?"Monsieur":"Madame",
            use_name: souscripteur[index]?.nom,
            first_name: souscripteur[index]?.prenom,
          },
          phone: [
            {
              type: "Mobile",
              country_prefix: souscripteur[0]?.country_prefix,
              number: souscripteur[0]?.phone,
            },
          ],
        });
      });
      setCustomerInformations(newCustomerInformations);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ContractFillHandler = async (
    num_contrat,
    file_url_bulletin,
    file_url_certif,
    id_affaire,
    mandat
  ) => {
    var files = [];
    let listProspects = [];
    if (souscripteur.length > 1) {
      souscripteur
        .filter((element) => element.relationship_rank >= 2)
        ?.forEach((element) => {
          listProspects = [
            ...listProspects,
            {
              name: element?.nom,
              surname: element?.prenom,
              DN: dayjs(element.dn).format("DD/MM/YYYY"),
              id: "",
              id_opp: "",
              campagne_id: "",
              post: "",
              interlocuteur: "",
              ville: "",
              situation: element?.relationship === "married" ? "married" : "",
              users_nom: "",
              users_prenom: "",
              adresse_mail: "",
              civilite: element?.civility,
              prospects_civ: "",

              prospects_situation: "",
              nb_enfants: "",
              adresses_adresse: "",
              streetNumber: "",
              CP: "",
              streetName: "",
              adresses_ville: "",
              complement_adr: "",
              tel: "",
              tel2: "",
              mobile: "",
              first_campagne: "",
              description: "",
              etat: "",
              lat: "",
              latitude: null,
              longitude: null,
              commercieux_id: "",
              commentaire: "",
              geom: "",
              tags: [],
              lng: "",
              date_naiss: "",
              collab: "",
              id_pros: "",
              id_camp: "",
              coment_plus: "",
              code: "",
              rue: "",
              numero: "",
              complement: "",
              mail: "",
              nb_enfant: "",
              infcomp: "",
              relation:
                element.relationship === "married" ? "Conjoint" : "Descendant",
              pays: "",
              invalidForms: [],
              deleted: false,
              information_bancaire: {
                id: "",
                titulaire_compte: "",
                iban: "",
                bic_swift: "",
                type_iban: "",
                nom_banque: "",
              },
              prospects_id: "",
            },
          ];
        });
    }

    files = [
      ...files,
      {
        url: file_url_bulletin,
        page:  proposition?.product_range === "PJ VIE PRIVE"
        ? parseInt(process.env.REACT_APP_SIGNATURE_PAGE_SIMPLE)
        : parseInt(process.env.REACT_APP_SIGNATURE_PAGE_PREMIUM),
        position: process.env.REACT_APP_SIGNATURE_POSITION_CONTRAT,
        file_name: "contract",
      },
      {
        url:mandat,
        page: 1,
        position: process.env.REACT_APP_SIGNATURE_MANDAT_POSITION,
        file_name:"Mandat Sepa"


      }

    ];
    let formData = {
      id_opp: sessionStorage.getItem("SessionURLID"),
      num_contrat: num_contrat?.replace("AE/FS", "02ODC228447/"),
      id_affaire: id_affaire,
      files: files,
      data: {
        info_specifiques_campagnes: {
          budget: null,
          ex43: null,
          ex44: null,
          ex47: null,
          ex31: null,
          ex32: null,
          ex11: null,
          ex12: null,
          ex16: null,
          ex19: null,
          pb: null,
          pbo: null,
          pbob: null,
          pnr: null,
        },
        info_specifiques_ligne_produit: {
          Ro: null,
          Ro_conjoint: null,
          annee_naissance: null,
          annee_naissance_conjoint: null,
          annee_naissance_enfant1: null,
          annee_naissance_enfant2: null,
          annee_naissance_enfant3: null,
          annee_naissance_enfant4: null,
          annee_naissance_enfant5: null,
          annee_naissance_enfant6: null,
          ro_enfant1: null,
          ro_enfant2: null,
          ro_enfant3: null,
          ro_enfant4: null,
          ro_enfant6: null,
          situation_pro: "111",
        },
        prospects: {
          listProspects: listProspects,
          mainProspect: {
            interlocuteur: null,
            tags: [],
            invalidForms: [],
            id: null,
            prospects_id: null,
            post: null,
            ville: souscripteur[0]?.ville,
            situation: null,
            adresse_mail: souscripteur[0]?.mail,
            civilite: souscripteur[0]?.civility,
            name: souscripteur[0]?.prenom,
            surname: souscripteur[0]?.nom,
            DN: dayjs(souscripteur[0]?.dn).format("DD/MM/YYYY"),
            nb_enfants:
              souscripteur[1]?.relationship === "married"
                ? souscripteur.length - 2
                : souscripteur.length - 1,
            streetNumber: null,
            longitude: null,
            latitude: null,
            CP: souscripteur[0]?.cp,
            streetName: null,
            complement_adr: null,
            tel: souscripteur[0]?.country_prefix + souscripteur[0]?.phone,
            tel2: null,
            mobile: souscripteur[0]?.country_prefix + souscripteur[0]?.phone,
            description: null,
            relation: "principal",
          },
        },
      },
    };

    // const response = await fetch(file_url_bulletin);
    // const blob = await response.blob();

    // let f = new FormData();
    // f.append("file[]", blob, "bulletin.pdf");
    // f.append("id_pros", sessionStorage.getItem("id_prospect"));
    // f.append("id_type_doc", 25);
    // f.append("id_affaire", id_affaire);
    // f.append("name_doc", "bulletin_");

    // axios
    //   .post(
    //     `${process.env.REACT_APP_GEOPROD_APIURL}/upload_document_affaire?`,
    //     f,
    //     {
    //       headers: {
    //         idSession: localStorage.getItem("token"),
    //       },
    //     }
    //   )
    //   .then(async (res) => {
        // const response = await fetch(file_url_certif);
        // const blob = await response.blob();

        // let f = new FormData();
        // f.append("file[]", blob, "certif.pdf");
        // f.append("id_pros", sessionStorage.getItem("id_prospect"));
        // f.append("id_type_doc", 30);
        // f.append("id_affaire", id_affaire);
        // f.append("name_doc", "certif");

        // axios.post(
        //   `${process.env.REACT_APP_GEOPROD_APIURL}/upload_document_affaire?`,
        //   f,
        //   {
        //     headers: {
        //       idSession: localStorage.getItem("token"),
        //     },
        //   }
        // );
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/yousign/e-yousign`,
        formData,
        {
          headers: {
            idSession: localStorage.getItem("api_key"),
            // "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
       
        navigate(`/Proposition/InformationBancaires/Success/?partnership_id=${localStorage.getItem("api_key")}`);
        setOpenbackdrop(false);
        // }
      })
      .catch((err) => {
        console.log(err);
        setOpenbackdrop(false);
      });
  };

  const subscribeHandler = async (signature_electronique) => {
    setOpenbackdrop(true);
    notification.destroy()
   

    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/subscription/subscribe_v2`,
        {
          call_source: "parcours",
          document_produit: "protection_juridique_vie_prive",
          id_opp: sessionStorage.getItem("SessionURLID"),
          ligne_produit: "14",
          fileType: "url",
          information_bancaire: {
            bic_swift: souscripteur[0]?.bicremboursement
              ? souscripteur[0]?.bicremboursement
              : souscripteur[0]?.bic,
            iban: souscripteur[0]?.ibanremboursement
              ? souscripteur[0]?.ibanremboursement
              : souscripteur[0]?.iban,
            type_iban: "prestation",
            titulaire_compte: `${souscripteur[0]?.nom} ${souscripteur[0]?.prenom}`,
            nom_banque: souscripteur[0]?.banqueRemboursement
              ? souscripteur[0]?.banqueRemboursement
              : souscripteur[0]?.banquePrelevement,
          },
          pricing: {
            third_app: {
              name: "ASolutions",
              id_external: 476820,
            },
            customer_information: customerInformations,
            contract_information: {
              effective_date: moment()
                .add(1, "month")
                .startOf("month")
                .format("DD/MM/yyyy"),
              mode_splitting: "Mensuel",
              signature_date: moment().format("DD/MM/yyyy"),
              payment_information: {
                mode_splitting: "Mensuel",
                withdrawal_day: souscripteur[0]?.jourprelevement,
                payment_mode: "Direct Debit",
                bic: souscripteur[0]?.bic,
                iban: souscripteur[0]?.iban,
                nom_banque: souscripteur[0]?.banquePrelevement,
                nom_prenom_debiteur: souscripteur[0]?.nomDebiteur,
              },
            },
          },
        },
        {
          headers: {
            idSession: localStorage.getItem("token"),
          },
        }
      )
      .then((result) => {
        
        // if (result.data?.error === true) {
        //   if (
        //     result.data?.message?.message?.contract_information
        //       ?.payment_information["476820"]?.iban
        //   ) {
        //     Swal.fire({
        //       icon: "error",
        //       text: result.data.message.message.contract_information
        //         .payment_information["476820"].iban,
        //       showConfirmButton: true,
        //       // timer: 3000,
        //     });
        //   } else {
        //     Swal.fire({
        //       icon: "error",
        //       title: "Erreur",
        //       text: result.data?.message.message,
        //     });
        //   }
        //   return setOpenbackdrop(false);
        // }
        ContractFillHandler(
          result.data?.num_contrat,
          result.data?.pdf,
          result.data?.pdf_certif,
          result.data?.id_affaire,
          result?.data?.mandat
        );
      })

      .catch((err) => {
        // Swal.fire({
        //   icon: "error",
        //   title: "Erreur",
        //   text: err,
        // });
        setOpenbackdrop(false);
        console.log(err);
      });
  };
  function valide() {
    if (!souscripteur[0].bic) {
      alertFunction("Veuillez remplir votre BIC.", "error");
    } else if (!souscripteur[0].iban ) {
      alertFunction("Veuillez remplir votre IBAN.", "error");
    } else if (
      isValidBic(souscripteur[0].bic) === false ||
      isValidBic(souscripteur[0]?.bicremboursement) === false
    ) {
      alertFunction("Veuillez vérifier votre BIC.", "error");
    } else if (
      isValidIBan(souscripteur[0].iban) === false ||
      isValidIBan(souscripteur[0].ibanremboursement) === false
    ) {
      alertFunction("Veuillez vérifier votre IBAN.", "error");
    } else if (
      !souscripteur[0]?.bicremboursement  &&
      souscripteur[0].ibanremboursement
    ) {
      alertFunction("Veuillez remplir votre BIC remboursement.", "error");
    } else if (
      souscripteur[0]?.bicremboursement &&
      !souscripteur[0].ibanremboursement 
    ) {
      alertFunction("Veuillez remplir votre IBAN remboursement.", "error");
    } else {
      subscribeHandler();
      // createHistory();
    }
  }

  return (
    <>
      <BackgroundImage color={productColor} />
      <div className={classes.container}>
        <Row className={classes.titleRow}>
          <Col className={classes.titleCol}>
            <h3 className={classes.title} style={{ color: productColor }}>
              Informations Bancaires
            </h3>
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form className={classes.form}>
            <Row>
              {rows.map((col, index) => {
                return col.map((val, idx) => {
                  return (
                    <InputComponent
                      key={idx}
                      label={val.label || ""}
                      value={val.value || ""}
                      disabled={val.disabled || ""}
                      name={val.name || ""}
                      type={val.type || ""}
                      required={val.required || ""}
                    />
                  );
                });
              })}{" "}
            </Row>
          </Form>
          <Row>
            <Col className={classes.RetourCol}>
              <Button
                className={classes.retourButton}
                style={{
                  backgroundColor: retourButtonColor,
                }}
                onClick={() => navigate(`/Proposition/?partnership_id=${localStorage.getItem("api_key")}`)}
                id="BTN_retour_3"
              >
                {" "}
                <FontAwesomeIcon
                  style={{ marginRight: "0.5rem" }}
                  icon={faArrowLeft}
                />
                Retour
              </Button>
            </Col>
            <Col>
              <Button
                className={classes.confirmButton}
                style={{
                  backgroundColor: confirmButtonColor,
                }}
                onClick={valide}
                id="BTN_confirmer_3"
              >
                Confirmer{" "}
                <FontAwesomeIcon
                  style={{ marginLeft: "0.5rem" }}
                  icon={faArrowRight}
                />
              </Button>
            </Col>
          </Row>
        </Row>
        <FloatButton
          id="BTN_clear_3"
            icon={<ClearOutlined />}
            tooltip={<div>Remise à zero</div>}
            onClick={() => {
              sessionStorage.clear();
             window.location.replace(`/?partnership_id=${localStorage.getItem("api_key")}`);
            }}
          />
      </div>
      {/* <Snackbar open={openBic} autoHideDuration={3000} onClose={handleCloseBic}>
        <Alert onClose={handleCloseBic} severity='error' sx={{ width: '100%' }}>
          Veuillez vérifier votre bic
        </Alert>
      </Snackbar>
      <Snackbar
        open={openIban}
        autoHideDuration={3000}
        onClose={handleCloseIban}
      >
        <Alert
          onClose={handleCloseIban}
          severity='error'
          sx={{ width: '100%' }}
        >
          Veuillez vérifier votre iban
        </Alert>
      </Snackbar> */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openbackdrop}
        /* onClick={handleCloseBackdrop} */
      >
        <div className={classes.loaderContainer}>
          <Box sx={{ width: "100%" }}>
            <CircularProgress style={{ width: "4rem", height: "4rem" }} />
          </Box>
          <h2>Veuillez patienter un instant.</h2>
        </div>
      </Backdrop>
    </>
  );
}

export default InformationsBancaires;
