import { notification } from "antd";

export function alertFunction(message, type) {
  if (type === "warning") {
    notification.warning({
      message: "Warning",
      description: message,
      duration: 0, 
      closable: true,
    
    });
  } else  {
    notification.error({
      message: "Erreur",
      description: message,
      duration: 0, 
      closable: true,
    
    });
  }
 
}
