import React from "react";
import { Col, Row } from "reactstrap";
import Souscripteur from "./Souscripteur/Souscripteur";
import classes from "./UserInformation.module.css";
import FamilyComposition from "./FamilyComposition/FamilyComposition";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import ParcoursContext from "../../Context/ParcoursContext";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import { notification } from "antd";
import { alertFunction } from "../../utils/alertFunction";
// import image from "../../assets/images/bcg-AS-parametre.svg";
function UserInformation() {
  const navigate = useNavigate();

  const {
    souscripteur,
    productColor,
    confirmButtonColor,
    retourButtonColor,
    villeOptions
  } = React.useContext(ParcoursContext);
  // const [errorMessage, setErrorMessage] = React.useState(
  //   "Vous devez remplir tous les champs avec *"
  // );
  function isValidNom(name) {
    var regex = new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[’'-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/);
    let validNom = regex.test(name);

    return validNom;
  }
  const nextHandler = () => {
    let errorMessage = "";
    let finaltest = souscripteur.every((val, index) => {

      let test = Object.keys(val).every((key) => {
        if (
          (key === "adresse" ||
            key === "civility" ||
            key === "country_prefix" ||
            key === "cp" ||
            key === "mail" ||
            key === "nom" ||
            key === "phone" ||
            key === "prenom" ) 
            &&
          val[key].replace(/ /g, "") === ""
        ) {
          errorMessage = "Veuillez remplir tous les champs obligatoires.";
          return false;
        }
        else if(key==="dn" && !val[key]){
          errorMessage = "Veuillez remplir tous les champs obligatoires.";
          return false;
        }
         else if (key === "mail") {
          var regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[a-zA-Z]{2,4}$/);
          let validEmail = regex.test(val[key].replace(/ /g, ""));
          errorMessage = "Veuillez verifier votre email.";
          return validEmail;
        } else if (key === "nom" && val?.relationship_rank===1) {
          
          if (isValidNom(val[key].replace(/ /g, "")) === false) {
            errorMessage = "Veuillez verifier votre nom.";
          }
          return isValidNom(val[key].replace(/ /g, ""));
        } else if(key === "nom" && val?.relationship_rank===2){
          if (isValidNom(val[key].replace(/ /g, "")) === false) {
            errorMessage = "Veuillez verifier le nom de votre conjoint.";
          }
          return isValidNom(val[key].replace(/ /g, ""));
        }
        else if(key === "nom" && val?.relationship_rank>=11){
          if (isValidNom(val[key].replace(/ /g, "")) === false) {
            errorMessage = `Veuillez verifier le nom de l'enfant ${val?.relationship_rank-10}.`;
          }
          return isValidNom(val[key].replace(/ /g, ""));
        }
        else if (key === "prenom"  && val?.relationship_rank===1) {
          if (isValidNom(val[key].replace(/ /g, "")) === false) {
            errorMessage = "Veuillez verifier votre prénom.";
          }
          return isValidNom(val[key].replace(/ /g, ""));
        } 
        else if (key === "prenom"  && val?.relationship_rank===2) {
          if (isValidNom(val[key].replace(/ /g, "")) === false) {
            errorMessage = "Veuillez verifier le prénom de votre conjoint.";
          }
          return isValidNom(val[key].replace(/ /g, ""));
        } 
        else if (key === "prenom"  && val?.relationship_rank>=11) {
          if (isValidNom(val[key].replace(/ /g, "")) === false) {
            errorMessage = `Veuillez verifier le prénom de l'enfant ${val?.relationship_rank-10}.`;
          }
          return isValidNom(val[key].replace(/ /g, ""));
        }
        // else if (key === "dn") {
        //   const nowYear = moment().year();
          // const yearBirthDate = parseInt(
          //   moment(val[key], "YYYY-DD-MM")?.format("YYYY")
          // );
          // let diffY = nowYear - yearBirthDate;
          // if (val.relationship_rank < 10 && diffY < 18) {
          //   errorMessage = "L'âge doit être supérieur à 18.";
          //   return false;
          // } else if (val.relationship_rank > 10 && (diffY > 18 || diffY < 0)) {
          //   errorMessage = "L'âge de l'enfant doit être entre 0 et 18 ans.";
          //   return false;
          // }
        // }

        else if (key === "country_prefix"){
         let prefReg=new RegExp(/^\+\d+$/)
          errorMessage = "Veuillez vérifier le préfixe de votre numéro de téléphone";
            return prefReg.test(val["country_prefix"].replace(/ /g, ""));
        }

        //  else if (key === "phone") {
        //   if (val["country_prefix"].replace(/ /g, "") === "+33") {
        //     var regexTel = new RegExp(/^0?[67]\d{8}$/);
        //     errorMessage = "Veuillez vérifier votre numéro de téléphone";
        //     return regexTel.test(val["phone"].replace(/ /g, ""));
        //   }
        // }
        return true;
      });
      return test;
    });
    // finaltest ? navigate("/Proposition") : setOpenf(true);
    if (finaltest) {
      navigate(`/Proposition/?partnership_id=${localStorage.getItem("api_key")}`);
      sessionStorage.setItem("souscripteur",JSON.stringify(souscripteur))
      sessionStorage.setItem("villeOptions",JSON.stringify(villeOptions))
      notification.destroy();
    } else {
      alertFunction(errorMessage, "error");
    }
  };

  return (
    <>
      <BackgroundImage color={productColor} />
      <section className={classes.container}>
        {/* <img src={image} /> */}

        <Row className={classes.titleRow}>
          <Col className={classes.titleCol}>
            <h3 className={classes.title} style={{ color: productColor }}>
              Parcours vie privée
            </h3>
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Souscripteur />
          <FamilyComposition />
        </Row>
        {/* <Row className={classes.confirm}>
            <Col
              xs="12"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {" "}
              <Button
                style={{ backgroundColor: "#18A0F5" }}
                onClick={nextHandler}
              >
                Confirmer
                <FontAwesomeIcon
                  style={{ marginLeft: "0.5rem" }}
                  icon={faArrowRight}
                />
              </Button>
            </Col>
          </Row> */}
        <Row style={{ marginTop: "2rem" }}>
          <Col className={classes.RetourCol}>
            <Button
              className={classes.retourButton}
              style={{
                backgroundColor: retourButtonColor,
              }}
              disabled={true}
              id="BTN_retour_1"
            >
              {" "}
              <FontAwesomeIcon
                style={{ marginRight: "0.5rem" }}
                icon={faArrowLeft}
              />
              Retour
            </Button>
          </Col>
          <Col>
            <Button
              className={classes.confirmButton}
              style={{
                backgroundColor: confirmButtonColor
                  ? confirmButtonColor
                  : "#254484",
              }}
              id="BTN_confirmer_1"
              onClick={nextHandler}
            >
              Confirmer{" "}
              <FontAwesomeIcon
                style={{
                  marginLeft: "0.5rem",
                }}
                icon={faArrowRight}
              />
            </Button>
          </Col>
        </Row>
      </section>
      {/* <Snackbar
        open={openf}
        autoHideDuration={3000}
        onClose={() => setOpenf(false)}
      >
        <Alert
          onClose={() => setOpenf(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar> */}
    </>
  );
}

export default UserInformation;
