import React from "react";
import { Col } from "react-bootstrap";
import ParcoursContext from "../../Context/ParcoursContext";
import classes from "./Card.module.css";
import buttonRadio from "../../assets/images/radio-on-button.png";
import buttonRadioGray from "../../assets/images/radio.png";
function Card({ index, val, selected, checked }) {
  const { setProposition, customerInformations,setCustomerInformations } =
    React.useContext(ParcoursContext);
  return (
    <Col
      lg="6"
      xs="12"
      md="6"
      key={index}
      id={val?.product_range}
      onClick={() => {
        setProposition(val);
        sessionStorage.setItem("proposition",JSON.stringify(val))
        let newData = [...customerInformations];
        newData?.map((e) => {
          e.contract_information = {
            ...e.contract_information,
            product:
              val?.product_range === "PJ VIE PRIVE"
                ? [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT_SIMPLE]
                : [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT],
          };
        });
        setCustomerInformations(newData)
        sessionStorage.setItem("globalData",JSON.stringify(newData))
      }}
      className={
        selected ? classes.BoxPropositionChecked : classes.BoxProposition
      }
    >
      <img
        src={selected ? buttonRadio : buttonRadioGray}
        alt="button"
        className={classes.ButtonRadioChecked}
      />
      <p className={classes.proposition}>
        {val.number === "1" ? "pj vie privée" : "pj vie privée premium "}
      </p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p className={classes.propositionTotal}>
          {" "}
          {checked ? val?.total_yearly : val?.total_monthly} €{" "}
        </p>
        <p className={classes.propositionType}>
          {" "}
          {checked ? "/ année" : "/ mois"}
        </p>
      </div>
    </Col>
  );
}

export default Card;
